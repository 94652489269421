import ViewPost from '../components/posts/ViewPost';


function PostPage() {  

    return (
        <ViewPost />
    );
}

export default PostPage;
